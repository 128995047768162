import React from "react";
import { Link } from "react-router-dom";

function Thanks() {
  return (
    <div className="bg-yellow-300 w-screen h-screen flex flex-col items-center justify-center">
      <img src="./logo2.png" alt="logo" className="h-20 mb-2" />
      <p className="mb-4 font-light">
        Thanks for subscribing to ISKCON Amsterdam
      </p>
      <Link
        to="/"
        className={`p-3 px-12 rounded-full text-white mt-6 ${"bg-indigo-500 mt-6 cursor-pointer active:bg-indigo-600"}`}
      >
        Home
      </Link>
    </div>
  );
}

export default Thanks;
