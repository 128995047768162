import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Main from './Main';
import Thanks from './Thanks';
import NotFound from './NotFound';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={Main} />
        <Route path="/thanks" exact component={Thanks} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
}

export default App;
