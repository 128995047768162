import { useState, useEffect } from "react";
import { RadioGroup } from "@headlessui/react";
import { subscribe, getStripePubKey } from './firebase-config';
import { loadStripe } from "@stripe/stripe-js";

const denominations = [11, 21, 51, 108, 201, 501];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Main() {
  const [radioAmount, setRadioAmount] = useState("");
  const [stripe, setStripe] = useState();

  useEffect(() => {
    async function load() {
      const requestOptions = {
        method: "GET",
      };

      const res = await fetch(
        getStripePubKey,
        requestOptions
      );

      const t = await res.json();

      const s = await loadStripe(t.data);
      setStripe(s);
    }
    load();
  }, []);
  
  const stripeCheckout = () => {
    if(denominations.indexOf(radioAmount) === -1) return;

    async function execute() {
      const reqBody = {
        index: denominations.indexOf(radioAmount)
      };

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(reqBody),
      };

      const res = await fetch(
        subscribe,
        requestOptions
      );

      const t = await res.json();

      stripe.redirectToCheckout({ sessionId: t.data });
    }
    execute();
  }

  const handleAmountInput = (value) => {
    setRadioAmount(value);
  };

  const checkUnselect = (v) => {
    if (radioAmount.toString() === v.target.innerText.replace("€", "").replace("/month", "")) {
      setRadioAmount("");
    }
  };

  return (
    <div className="bg-yellow-300 w-screen h-screen flex flex-col items-center justify-center">
      <img src="./logo2.png" alt="logo" className="h-20 mb-2" / >
      <p className="mb-4 font-light">Subscribe to ISKCON Amsterdam</p>
      <RadioGroup
        value={radioAmount}
        onChange={(v) => handleAmountInput(v, true)}
        onClick={checkUnselect}
        className="bg-yellow-400 p-4 rounded-lg"
      >
        <RadioGroup.Label className="sr-only">Choose amount</RadioGroup.Label>

        <div className="grid grid-cols-3 gap-3">
          {denominations.map((denom, i) => (
            <RadioGroup.Option
              key={i}
              value={denom}
              className={({ active, checked }) =>
                classNames(
                  "cursor-pointer focus:outline-none",
                  active ? "ring-1 ring-offset-1 ring-indigo-500" : "",
                  checked
                    ? "bg-indigo-500 border-transparent text-white hover:bg-indigo-500"
                    : "bg-white border-gray-200 text-gray-900 hover:bg-indigo-100",
                  "border rounded-md py-3 px-3 flex items-center justify-center text-sm font-medium"
                )
              }
            >
              <RadioGroup.Label as="p">€{denom}<span className="text-gray-300 pointer-events-none">/month</span></RadioGroup.Label>
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
      <div onClick={stripeCheckout} className={`p-3 px-12 rounded-full text-white mt-6 ${radioAmount===''?'bg-gray-400 mt-6 cursor-not-allowed':'bg-indigo-500 mt-6 cursor-pointer active:bg-indigo-600'}`}>SUBSCRIBE</div>
    </div>
  );
}

export default Main;
